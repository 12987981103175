/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      title
      summary
      description
      timeStart
      timeEnd
      audience
      cost
      price
      contactId
      type
      faculty
      school
      areaOfInterest
      image {
        key
        bucket
        region
      }
      moreInfo
      owner
      published
      createdAt
      contact {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      venues {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      title
      summary
      description
      timeStart
      timeEnd
      audience
      cost
      price
      contactId
      type
      faculty
      school
      areaOfInterest
      image {
        key
        bucket
        region
      }
      moreInfo
      owner
      published
      createdAt
      contact {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      venues {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      title
      summary
      description
      timeStart
      timeEnd
      audience
      cost
      price
      contactId
      type
      faculty
      school
      areaOfInterest
      image {
        key
        bucket
        region
      }
      moreInfo
      owner
      published
      createdAt
      contact {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      venues {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const onCreateAreaOfInterest = /* GraphQL */ `
  subscription OnCreateAreaOfInterest {
    onCreateAreaOfInterest {
      id
      name
    }
  }
`;
export const onUpdateAreaOfInterest = /* GraphQL */ `
  subscription OnUpdateAreaOfInterest {
    onUpdateAreaOfInterest {
      id
      name
    }
  }
`;
export const onDeleteAreaOfInterest = /* GraphQL */ `
  subscription OnDeleteAreaOfInterest {
    onDeleteAreaOfInterest {
      id
      name
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      title
      firstName
      lastName
      email
      address
      telephone
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      title
      firstName
      lastName
      email
      address
      telephone
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact {
    onDeleteContact {
      id
      title
      firstName
      lastName
      email
      address
      telephone
    }
  }
`;
export const onCreateEventType = /* GraphQL */ `
  subscription OnCreateEventType {
    onCreateEventType {
      id
      name
    }
  }
`;
export const onUpdateEventType = /* GraphQL */ `
  subscription OnUpdateEventType {
    onUpdateEventType {
      id
      name
    }
  }
`;
export const onDeleteEventType = /* GraphQL */ `
  subscription OnDeleteEventType {
    onDeleteEventType {
      id
      name
    }
  }
`;
export const onCreateEventVenue = /* GraphQL */ `
  subscription OnCreateEventVenue {
    onCreateEventVenue {
      id
      eventId
      venueId
      event {
        id
        title
        summary
        description
        timeStart
        timeEnd
        audience
        cost
        price
        contactId
        type
        faculty
        school
        areaOfInterest
        image {
          key
          bucket
          region
        }
        moreInfo
        owner
        published
        createdAt
        contact {
          id
          title
          firstName
          lastName
          email
          address
          telephone
        }
        venues {
          nextToken
        }
      }
      venue {
        id
        name
        code
        location
        event {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateEventVenue = /* GraphQL */ `
  subscription OnUpdateEventVenue {
    onUpdateEventVenue {
      id
      eventId
      venueId
      event {
        id
        title
        summary
        description
        timeStart
        timeEnd
        audience
        cost
        price
        contactId
        type
        faculty
        school
        areaOfInterest
        image {
          key
          bucket
          region
        }
        moreInfo
        owner
        published
        createdAt
        contact {
          id
          title
          firstName
          lastName
          email
          address
          telephone
        }
        venues {
          nextToken
        }
      }
      venue {
        id
        name
        code
        location
        event {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteEventVenue = /* GraphQL */ `
  subscription OnDeleteEventVenue {
    onDeleteEventVenue {
      id
      eventId
      venueId
      event {
        id
        title
        summary
        description
        timeStart
        timeEnd
        audience
        cost
        price
        contactId
        type
        faculty
        school
        areaOfInterest
        image {
          key
          bucket
          region
        }
        moreInfo
        owner
        published
        createdAt
        contact {
          id
          title
          firstName
          lastName
          email
          address
          telephone
        }
        venues {
          nextToken
        }
      }
      venue {
        id
        name
        code
        location
        event {
          nextToken
        }
      }
    }
  }
`;
export const onCreateVenue = /* GraphQL */ `
  subscription OnCreateVenue {
    onCreateVenue {
      id
      name
      code
      location
      event {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const onUpdateVenue = /* GraphQL */ `
  subscription OnUpdateVenue {
    onUpdateVenue {
      id
      name
      code
      location
      event {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const onDeleteVenue = /* GraphQL */ `
  subscription OnDeleteVenue {
    onDeleteVenue {
      id
      name
      code
      location
      event {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const onCreateProducer = /* GraphQL */ `
  subscription OnCreateProducer {
    onCreateProducer {
      id
      faculty
      school
    }
  }
`;
export const onUpdateProducer = /* GraphQL */ `
  subscription OnUpdateProducer {
    onUpdateProducer {
      id
      faculty
      school
    }
  }
`;
export const onDeleteProducer = /* GraphQL */ `
  subscription OnDeleteProducer {
    onDeleteProducer {
      id
      faculty
      school
    }
  }
`;
