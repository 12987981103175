import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu, Button, Avatar } from 'antd';
import {
  HomeOutlined,
  AppstoreOutlined,
  SettingOutlined,
  UserOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import EventContext from '../../context/event/eventContext';

const { SubMenu } = Menu;

const Navbar = ({ handleSignout }) => {
  const eventContext = useContext(EventContext);
  const { user, isAdmin } = eventContext;

  const location = useLocation();

  const [current, setCurrent] = useState('home');

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    const pathArr = location.pathname.split('/');
    let currentPath = 'home';
    switch (pathArr[1]) {
      case 'addsingle':
        currentPath = 'event:1';
        break;
      case 'addmulti':
        currentPath = 'event:2';
        break;
      case 'lookups':
        switch (pathArr[2]) {
          case 'area':
            currentPath = 'lookups:1';
            break;
          case 'contact':
            currentPath = 'lookups:2';
            break;
          case 'type':
            currentPath = 'lookups:3';
            break;
          case 'producer':
            currentPath = 'lookups:4';
            break;
          case 'lookups':
            currentPath = 'lookups:5';
            break;
          default:
            break;
        }
        break;
      default:
        currentPath = 'home';
        break;
    }
    setCurrent(currentPath);
  }, [location]);

  return (
    <div className='nav-container'>
      <div className='menu-container'>
        <span className='logo'>
          <img
            src='/usyd_logo_new.png'
            height='60'
            alt='The University of Sydney'
          />{' '}
          EventsAdmin
        </span>
        <Menu
          onClick={handleClick}
          selectedKeys={[current]}
          mode='horizontal'
          className='menus'
        >
          <Menu.Item key='home' icon={<HomeOutlined />}>
            <NavLink to='/'>{isAdmin ? 'All' : 'My'} Events</NavLink>
          </Menu.Item>
          <SubMenu key='event' icon={<AppstoreOutlined />} title='Add Event'>
            <Menu.Item key='event:1'>
              <NavLink to='/addsingle'>Single Event</NavLink>
            </Menu.Item>
            <Menu.Item key='event:2'>
              <NavLink to='/addmulti'>Recurring Events</NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key='lookups'
            icon={<SettingOutlined />}
            title='Manage Lookup Table'
          >
            <Menu.Item key='lookups:1'>
              <NavLink to='/lookups/area'>Area of Interest</NavLink>
            </Menu.Item>
            <Menu.Item key='lookups:2'>
              <NavLink to='/lookups/contact'>Contacts</NavLink>
            </Menu.Item>
            <Menu.Item key='lookups:3'>
              <NavLink to='/lookups/type'>Event types</NavLink>
            </Menu.Item>
            <Menu.Item key='lookups:4'>
              <NavLink to='/lookups/producer'>Producers</NavLink>
            </Menu.Item>
            <Menu.Item key='lookups:5'>
              <NavLink to='/lookups/venue'>Venues</NavLink>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key='livesite' icon={<LinkOutlined />}>
            <a
              href='http://whatson.sydney.edu.au'
              target='_blank'
              rel='noopener noreferrer'
            >
              Live Site
            </a>
          </Menu.Item>
        </Menu>
      </div>

      <div className='app-user'>
        <div className='app-user__name'>
          <Avatar
            style={{ backgroundColor: '#f56a00', marginRight: 4 }}
            size={16}
            icon={<UserOutlined />}
          />{' '}
          {user && user.username.replace(`${process.env.REACT_APP_IDP}_`, '')}{' '}
          {isAdmin && '(Admin)'}
        </div>
        <Button danger style={{ borderRadius: 4 }} onClick={handleSignout}>
          Sign Out
        </Button>
      </div>
    </div>
  );
};

export default Navbar;
