import React, { useState, useContext } from 'react';
import { Table, Space, Button, notification } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import {
  createContact,
  updateContact,
  deleteContact,
} from '../../graphql/mutations';
import DeletePopover from '../DeletePopover';
import ContactDrawer from './ContactDrawer';
import {
  EditOutlined,
  DeleteFilled,
  PlusCircleFilled,
} from '@ant-design/icons';
import EventContext from '../../context/event/eventContext';

export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

const ContactList = () => {
  const eventContext = useContext(EventContext);
  const { contacts } = eventContext;

  const initialContact = {
    id: null,
    title: '',
    firstName: '',
    lastName: '',
    address: '',
    telephone: '',
    email: '',
  };
  const [isShowing, setIsShowing] = useState(false);
  const [mode, setMode] = useState('add');
  const [currentContact, setCurrentContact] = useState({ ...initialContact });

  const contactColumns = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'LAST NAME',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'ACTION',
      key: 'action',
      width: '200px',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            shape='round'
            size='small'
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <DeletePopover deleteHandler={handleDeleteContact} record={record}>
            <Button shape='round' size='small' danger icon={<DeleteFilled />}>
              Delete
            </Button>
          </DeletePopover>
        </Space>
      ),
    },
  ];

  const handleEdit = (contact) => {
    setMode('edit');
    setCurrentContact(contact);
    setIsShowing(true);
  };

  const closeDrawer = () => {
    setMode('add');
    setCurrentContact({ ...initialContact });
    setIsShowing(false);
  };

  const handleAddContact = async (values) => {
    //console.log("Add Contact", values)
    try {
      const input = {
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        address: values.address,
        telephone: values.telephone,
        email: values.email,
      };
      await API.graphql(graphqlOperation(createContact, { input }));
      closeDrawer();
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Contact has been added successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditContact = async (values) => {
    //console.log("Edit Contact", values)
    try {
      const input = {
        id: currentContact.id,
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        address: values.address,
        telephone: values.telephone,
        email: values.email,
      };
      await API.graphql(graphqlOperation(updateContact, { input }));
      closeDrawer();
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Contact has been updated successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteContact = async (id) => {
    try {
      const options = {
        filter: {
          contactId: {
            eq: id,
          },
        },
      };
      let result = await API.graphql(graphqlOperation(listEvents, options));
      // Check whether contact is currently being used by any event
      if (result.data.listEvents.items.length > 0) {
        notification.error({
          message: 'Deletion Failed',
          duration: 4,
          description:
            "Cannot delete this contact because it's currently being used.",
        });
      } else {
        const input = { id };
        await API.graphql(graphqlOperation(deleteContact, { input }));
        notification.success({
          message: 'Success',
          duration: 2,
          description: 'Contact has been deleted successfully.',
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onFinishFailed = (error) => {
    console.error('Failed:', error);
  };

  const dataSource = contacts.map((item) => {
    return {
      ...item,
      key: item.id,
    };
  });

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '8px',
        }}
      >
        <h2>
          Total number of Contacts
          <span className='badge'>{dataSource.length}</span>
        </h2>
        <Button
          shape='round'
          size='small'
          type='primary'
          onClick={() => setIsShowing(true)}
        >
          <PlusCircleFilled /> Add Contact
        </Button>
      </div>

      {isShowing && (
        <ContactDrawer
          title='contact'
          mode={mode}
          isShowing={isShowing}
          closeDrawer={closeDrawer}
          handleAddContact={handleAddContact}
          handleEditContact={handleEditContact}
          onFinishFailed={onFinishFailed}
          currentEntry={currentContact}
        />
      )}

      <Table
        dataSource={dataSource}
        columns={contactColumns}
        pagination={{ pageSize: 50 }}
        size='small'
      />
    </>
  );
};

export default ContactList;
