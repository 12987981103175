import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const DateRangePickerAutoaccept = (props) => {
  const rangePickerRef = useRef();

  const onBlur = () => {
    const rangePickerDomNode = ReactDOM.findDOMNode(rangePickerRef.current);
    const [startInput, endInput] = rangePickerDomNode.querySelectorAll(
      '.ant-picker-input input'
    );
    const startValue = moment(startInput.value, 'DD-MMM-YYYY HH:mm');
    let endValue = moment(endInput.value, 'DD-MMM-YYYY HH:mm');
    if (!endValue.isValid()) {
      endValue = null;
    }
    if (startValue && startValue.isValid() && props.onChange) {
      props.onChange([startValue, endValue]);
    }
  };

  return (
    <RangePicker
      {...props}
      onBlur={onBlur}
      ref={rangePickerRef}
      format='DD-MMM-YYYY HH:mm'
    />
  );
};
