import React from 'react';
import { Input } from 'antd';

const Search = Input.Search;

const TitleSearch = ({ onSearch, ...props }) => (
  <div {...props}>
    <Search
      placeholder='Search Event Title...'
      onSearch={onSearch}
      allowClear
      role='search'
      style={{ width: 300 }}
    />
  </div>
);

export default TitleSearch;
