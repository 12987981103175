export const SET_USER = "SET_USER";
export const SET_ADMIN = "SET_ADMIN";
export const SET_LOADING = "SET_LOADING";
export const SET_CONSENT = "SET_CONSENT";

export const ADD_AREA = "ADD_AREA";
export const SET_AREAS = "SET_AREAS";
export const UPDATE_AREA = "UPDATE_AREA";
export const DELETE_AREA = "DELETE_AREA";

export const ADD_CONTACT = "ADD_CONTACT";
export const SET_CONTACTS = "SET_CONTACTS";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

export const ADD_EVENT = "ADD_EVENT";
export const SET_EVENTS = "SET_EVENTS";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";

export const ADD_EVENT_TYPE = "ADD_EVENT_TYPE";
export const SET_EVENT_TYPES = "SET_EVENT_TYPES";
export const UPDATE_EVENT_TYPE = "UPDATE_EVENT_TYPE";
export const DELETE_EVENT_TYPE = "DELETE_EVENT_TYPE";

export const ADD_PRODUCER = "ADD_PRODUCER";
export const SET_PRODUCERS = "SET_PRODUCERS";
export const UPDATE_PRODUCER = "UPDATE_PRODUCER";
export const DELETE_PRODUCER = "DELETE_PRODUCER";

export const ADD_VENUE = "ADD_VENUE";
export const SET_VENUES = "SET_VENUES";
export const UPDATE_VENUE = "UPDATE_VENUE";
export const DELETE_VENUE = "DELETE_VENUE";
