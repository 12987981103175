/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:441c9a74-cfde-4440-98b5-d6542c1e9a4a",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_0yQnlyQIR",
    "aws_user_pools_web_client_id": "ainpm4ptta52fkanjbmus7381",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://tkxkzzh2kbgtfazagyuhf6lmey.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tzintxj7szft3j76hn5xpvg4pq",
    "aws_content_delivery_bucket": "eventscalendar-20201117130619-hostingbucket-coders",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d3p3yy0zyzycbq.cloudfront.net",
    "aws_user_files_s3_bucket": "eventscalendar14a645cd782b43058d535c002f33e89052439-coders",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
