import React from "react";
import { Drawer, Button, Form, Input, Space } from "antd";

const ContactDrawer = ({
  title,
  mode,
  isShowing,
  closeDrawer,
  handleAddContact,
  handleEditContact,
  onFinishFailed,
  currentEntry,
}) => {
  return (
    <Drawer
      title={`${mode === "add" ? "Add" : "Edit"} ${title}`}
      width={640}
      onClose={closeDrawer}
      visible={isShowing}
      mode={mode}
    >
      <Form
        layout='vertical'
        initialValues={currentEntry}
        onFinish={mode === "add" ? handleAddContact : handleEditContact}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name='title' label='Title'>
          <Input placeholder='Please enter title...' />
        </Form.Item>

        <Form.Item
          name='firstName'
          label='First Name'
          rules={[{ required: true, message: "Please enter first name" }]}
        >
          <Input placeholder='Please enter first name...' />
        </Form.Item>

        <Form.Item
          name='lastName'
          label='Last Name'
          rules={[{ required: true, message: "Please enter last name" }]}
        >
          <Input placeholder='Please enter last name...' />
        </Form.Item>

        <Form.Item name='address' label='Address'>
          <Input placeholder='Please enter address...' />
        </Form.Item>

        <Form.Item name='telephone' label='Telephone'>
          <Input placeholder='Please enter phone number...' />
        </Form.Item>

        <Form.Item
          name='email'
          label='Email'
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input placeholder='Please enter email...' />
        </Form.Item>

        <Form.Item
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            <Button onClick={closeDrawer}>Cancel</Button>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ContactDrawer;
