import React from "react";
import { Modal, Form, Input } from "antd";

const BasicCreateForm = ({ visible, onCreate, onCancel, type }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title={`Add new ${type}`}
      okText='Create'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validation Failed:", info);
          });
      }}
    >
      <Form form={form} layout='vertical' name='venue_form'>
        <Form.Item
          name='name'
          label='Name'
          rules={[{ required: true, message: `Please enter ${type} name` }]}
        >
          <Input placeholder={`Please enter ${type} name...`} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BasicCreateForm;
