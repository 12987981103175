import React, { useState, useContext } from 'react';
import { Table, Space, Button, notification } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import {
  createEventType,
  updateEventType,
  deleteEventType,
} from '../../graphql/mutations';
import DeletePopover from '../DeletePopover';
import BasicDrawer from '../BasicDrawer';
import {
  EditOutlined,
  DeleteFilled,
  PlusCircleFilled,
} from '@ant-design/icons';
import EventContext from '../../context/event/eventContext';

const EventTypeList = () => {
  const eventContext = useContext(EventContext);
  const { isAdmin, eventTypes } = eventContext;

  const [isShowing, setIsShowing] = useState(false);
  const [mode, setMode] = useState('add');
  const [currentEventType, setCurrentEventType] = useState({
    id: null,
    name: '',
  });

  const eventTypeColumns = [
    {
      title: 'EVENT TYPE',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'ACTION',
      key: 'action',
      width: '200px',
      render: (text, record) =>
        record.showButtons ? (
          <Space size='middle'>
            <Button
              shape='round'
              size='small'
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            >
              Edit
            </Button>
            <DeletePopover
              deleteHandler={handleDeleteEventType}
              record={record}
            >
              <Button shape='round' size='small' danger icon={<DeleteFilled />}>
                Delete
              </Button>
            </DeletePopover>
          </Space>
        ) : (
          '- -'
        ),
    },
  ];

  const handleEdit = (type) => {
    setMode('edit');
    setCurrentEventType({
      id: type.id,
      name: type.name,
    });
    setIsShowing(true);
  };

  const closeDrawer = () => {
    setMode('add');
    setCurrentEventType({ id: null, name: '' });
    setIsShowing(false);
  };

  const handleAddEventType = async (values) => {
    // console.log('Add Event Type:', values);
    try {
      const input = { name: values.name };
      await API.graphql(graphqlOperation(createEventType, { input }));
      closeDrawer();
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Event type has been added successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditEventType = async (values) => {
    // console.log("Edit Event Type", values)
    try {
      const input = {
        id: currentEventType.id,
        name: values.name,
      };
      await API.graphql(graphqlOperation(updateEventType, { input }));
      closeDrawer();
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Event type has been updated successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteEventType = async (id) => {
    try {
      const input = { id };
      await API.graphql(graphqlOperation(deleteEventType, { input }));
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Area of interest has been deleted successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const dataSource = eventTypes.map((item) => {
    return {
      ...item,
      key: item.id,
      showButtons: isAdmin,
    };
  });

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '8px',
        }}
      >
        <h2>
          Total number of event types
          <span className='badge'>{dataSource.length}</span>
        </h2>
        {isAdmin && (
          <Button
            shape='round'
            size='small'
            type='primary'
            onClick={() => setIsShowing(true)}
          >
            <PlusCircleFilled /> Add Event Type
          </Button>
        )}
      </div>

      {isShowing && (
        <BasicDrawer
          title='event type'
          mode={mode}
          isShowing={isShowing}
          closeDrawer={closeDrawer}
          handleAddName={handleAddEventType}
          handleEditName={handleEditEventType}
          onFinishFailed={onFinishFailed}
          currentEntry={currentEventType}
        />
      )}

      <Table
        dataSource={dataSource}
        columns={eventTypeColumns}
        pagination={{ pageSize: 20 }}
        size='small'
      />
    </div>
  );
};

export default EventTypeList;
