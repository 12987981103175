import React from "react";
import { Modal, Form, Input } from "antd";

const ProducerCreateForm = ({ visible, onCreate, onCancel, type }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title={`Add new ${type}`}
      okText='Create'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validation Failed:", info);
          });
      }}
    >
      <Form form={form} layout='vertical' name='producer_form'>
        <Form.Item
          name='faculty'
          label='Faculty'
          rules={[{ required: true, message: "Please enter faculty name" }]}
        >
          <Input placeholder='Please enter faculty name...' />
        </Form.Item>

        <Form.Item name='school' label='School'>
          <Input placeholder='Please enter school name...' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProducerCreateForm;
