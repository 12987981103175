import React from "react";
import AreaList from "../components/area/AreaList";
import EventTypeList from "../components/eventType/EventTypeList";
import ContactList from "../components/contact/ContactList";
import VenueList from "../components/venue/VenueList";
import ProducerList from "../components/producer/ProducerList";

const Lookup = ({ name }) => {
  switch (name) {
    case "contact":
      return <ContactList />;
    case "venue":
      return <VenueList />;
    case "type":
      return <EventTypeList />;
    case "producer":
      return <ProducerList />;
    default:
      return <AreaList />;
  }
};

export default Lookup;
