import React, { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import {
  createEvent,
  createEventVenue,
  updateEvent,
  deleteEventVenue,
  createVenue,
  createEventType,
  createAreaOfInterest,
  createContact,
  createProducer,
} from '../graphql/mutations';
import { PhotoPicker } from 'aws-amplify-react';
import {
  Form,
  Input,
  Button,
  Switch,
  Space,
  Col,
  notification,
  Spin,
  Cascader,
  Radio,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  PlusCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { Select } from 'antd';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import aws_exports from '../aws-exports';
import { S3Image } from 'aws-amplify-react';
import VenueCreateForm from './venue/VenueCreateForm';
import BasicCreateForm from './BasicCreateForm';
import ContactCreateForm from './contact/ContactCreateForm';
import ProducerCreateForm from './producer/ProducerCreateForm';
import EventContext from '../context/event/eventContext';
import { DateRangePickerAutoaccept } from './DateRangePickerAutoaccept';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-paste-smart';

const modules = {
  toolbar: ['bold', 'italic', 'underline'],
  clipboard: {
    allowed: {
      tags: ['b', 'strong', 'u', 'i', 'p', 'br', 'em'],
    },
    keepSelection: true,
  },
};

const { TextArea } = Input;
const { Option } = Select;
const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 16, color: 'white', marginRight: 16 }}
    spin
  />
);

const EventForm = (props) => {
  const eventContext = useContext(EventContext);
  const {
    areaOfInterests,
    contacts,
    eventTypes,
    venues,
    producers,
    isAdmin,
    defaultVenue,
  } = eventContext;

  const { initialValues, mode } = props;
  const defaultValues = {
    ...initialValues,
  };

  const [form] = Form.useForm();

  const [publishing, setPublishing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState('');
  const [imagePreview, setImagePreview] = useState('');

  const [showPrice, setShowPrice] = useState(false);

  const [venueFormVisible, setVenueFormVisible] = useState(false);
  const [eventTypeFormVisible, setEventTypeFormVisible] = useState(false);
  const [areaOfInterestFormVisible, setAreaOfInterestFormVisible] =
    useState(false);
  const [contactFormVisible, setContactFormVisible] = useState(false);
  const [producerFormVisible, setProducerFormVisible] = useState(false);

  useEffect(() => {
    if (mode === 'add') {
      defaultValues.venue = defaultVenue ? [defaultVenue.id] : [];
    }
    form.resetFields();
    setPublishing(initialValues.published);
    setShowPrice(initialValues.cost !== 'free');
    // eslint-disable-next-line
  }, [initialValues, defaultVenue]);

  const onFinish = async (fieldsValue) => {
    // console.log(fieldsValue)
    setLoading(true);
    const commonInput = {
      areaOfInterest: fieldsValue.areaOfInterest,
      contactId: fieldsValue.contact || null,
      cost: fieldsValue.cost,
      audience: fieldsValue.audience,
      price: fieldsValue.cost === 'free' ? '' : fieldsValue.price,
      description: fieldsValue.description,
      moreInfo: fieldsValue.moreInfo,
      faculty:
        fieldsValue.producer && fieldsValue.producer[0]
          ? fieldsValue.producer[0]
          : '',
      school:
        fieldsValue.producer && fieldsValue.producer[1]
          ? fieldsValue.producer[1]
          : '',
      published: fieldsValue.published,
      summary: fieldsValue.summary,
      timeStart: fieldsValue['range-time-picker'][0].toISOString(),
      timeEnd: fieldsValue['range-time-picker'][1].toISOString(),
      title: fieldsValue.title,
      type: fieldsValue.type,
    };

    // console.log('common input is', commonInput)
    // console.log("IMAGE VALUE", form.getFieldValue('image'))
    const user = await Auth.currentAuthenticatedUser();

    try {
      if (mode !== 'edit') {
        // Create new event
        //const visibility = "public";
        const { identityId } = await Auth.currentCredentials();
        // const filename = `/${visibility}/${identityId}/${Date.now()}-${image.name}`;
        const filename = `${identityId}/${Date.now()}-${image.name}`;
        const uploadedFile = await Storage.put(filename, image.file, {
          contentType: image.type,
        });
        const file = {
          key: uploadedFile.key,
          bucket: aws_exports.aws_user_files_s3_bucket,
          region: aws_exports.aws_project_region,
        };

        const input = {
          ...commonInput,
          image: file,
          owner: user.username,
        };
        // console.log(input)
        const result = await API.graphql(
          graphqlOperation(createEvent, { input })
        );

        // console.log("Event info", result)
        const eventId = result.data.createEvent.id;
        // Add event venue
        fieldsValue.venue &&
          fieldsValue.venue.forEach(async (venue) => {
            const venueInput = {
              eventId,
              venueId: venue,
            };

            await API.graphql(
              graphqlOperation(createEventVenue, {
                input: venueInput,
              })
            );
          });
      } else {
        const input = {
          ...commonInput,
          id: initialValues.id,
        };

        if (form.getFieldValue('image') === 'image updated') {
          // const visibility = "public";
          const { identityId } = await Auth.currentCredentials();
          // const filename = `/${visibility}/${identityId}/${Date.now()}-${
          //   image.name
          // }`;
          const filename = `${identityId}/${Date.now()}-${image.name}`;
          const uploadedFile = await Storage.put(filename, image.file, {
            contentType: image.type,
          });
          const file = {
            key: uploadedFile.key,
            bucket: aws_exports.aws_user_files_s3_bucket,
            region: aws_exports.aws_project_region,
          };
          input['image'] = file;
        }

        const venuesToAdd = fieldsValue.venue.filter(
          (x) => !initialValues.venue.includes(x)
        );
        const venuesToDelete = initialValues.venue.filter(
          (x) => !fieldsValue.venue.includes(x)
        );

        const joinTableIdsToDelete = initialValues.allVenues
          .filter((x) => venuesToDelete.includes(x.venue.id))
          .map((x) => x.id);

        // console.log(venuesToAdd, venuesToDelete, joinTableIdsToDelete)
        await API.graphql(graphqlOperation(updateEvent, { input }));

        // Add new venue
        venuesToAdd.length > 0 &&
          venuesToAdd.forEach(async (venue) => {
            const venueInput = {
              eventId: initialValues.id,
              venueId: venue,
            };

            await API.graphql(
              graphqlOperation(createEventVenue, {
                input: venueInput,
              })
            );
          });

        // Remove deleted venue
        joinTableIdsToDelete.length > 0 &&
          joinTableIdsToDelete.forEach(async (id) => {
            const input = {
              id,
            };

            await API.graphql(
              graphqlOperation(deleteEventVenue, {
                input,
              })
            );
          });
      }
      props.history.push('/');
    } catch (err) {
      console.log('error adding/editing event', err);
    }
  };

  const validateImage = (file) => {
    form.setFieldsValue({ image: 'image updated' });
    setImage(file);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onCreateVenue = async (values) => {
    // console.log('Receive Venue values from form: ', values);
    setVenueFormVisible(false);
    try {
      await API.graphql(graphqlOperation(createVenue, { input: values }));
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Venue has been added successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onCreateEventType = async (values) => {
    // console.log('Receive Event Type values from form: ', values);
    setEventTypeFormVisible(false);
    try {
      await API.graphql(graphqlOperation(createEventType, { input: values }));
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Event Type has been added successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onCreateAreaOfInterest = async (values) => {
    // console.log('Receive Area of Interest values from form: ', values);
    setAreaOfInterestFormVisible(false);
    try {
      await API.graphql(
        graphqlOperation(createAreaOfInterest, { input: values })
      );
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Area of Interest has been added successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onCreateContact = async (values) => {
    // console.log('Receive Contact values from form: ', values);
    setContactFormVisible(false);
    try {
      await API.graphql(graphqlOperation(createContact, { input: values }));
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Contact has been added successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onCreateProducer = async (values) => {
    // console.log('Receive Producer values from form: ', values);
    setProducerFormVisible(false);
    try {
      await API.graphql(graphqlOperation(createProducer, { input: values }));
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Producer has been added successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onChangePrice = (e) => {
    setShowPrice(e.target.value !== 'free');
  };

  let producer_options = {};
  producers.forEach((producer) => {
    if (producer.school === '') {
      producer_options[producer.faculty] = {
        value: producer.faculty,
        label: producer.faculty,
      };
    } else {
      if (
        producer_options[producer.faculty] &&
        Array.isArray(producer_options[producer.faculty].children)
      ) {
        producer_options[producer.faculty].children.push({
          value: producer.school,
          label: producer.school,
        });
      } else {
        producer_options[producer.faculty] = {
          value: producer.faculty,
          label: producer.faculty,
          children: [
            {
              value: producer.school,
              label: producer.school,
            },
          ],
        };
      }
    }
  });
  const options = Object.values(producer_options);

  const filter = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const publishingText = publishing
    ? 'This event will be published for public access.'
    : 'This event will be NOT published for public access.';

  return (
    <div>
      <Form
        form={form}
        {...layout}
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={defaultValues}
      >
        <Form.Item
          label='Title'
          name='title'
          htmlFor='title'
          rules={[{ required: true, message: 'Title is required!' }]}
        >
          <Input id='title' />
        </Form.Item>

        <Form.Item
          label='Summary'
          name='summary'
          htmlFor='summary'
          rules={[{ required: true, message: 'Summary is required!' }]}
        >
          <TextArea rows={5} showCount maxLength={350} id='summary' />
        </Form.Item>

        <Form.Item
          label='Description'
          name='description'
          htmlFor='description'
          rules={[{ required: true, message: 'Description is required!' }]}
        >
          <ReactQuill
            modules={modules}
            id='description'
            placeholder="Please note that one line break (ie. 'return') will create a new paragraph. Additional line breaks are not required to space your paragraphs. To see how your event description will be displayed, click 'view' under your 'My Events' tab."
          />
        </Form.Item>

        <Form.Item
          name='range-time-picker'
          label='Start/End Time'
          htmlFor='starttime'
          {...rangeConfig}
        >
          <DateRangePickerAutoaccept
            showTime
            format='YYYY-MM-DD HH:mm'
            minuteStep={5}
            id='starttime'
          />
        </Form.Item>

        <Form.Item
          name='audience'
          label='Audience'
          htmlFor='audience'
          rules={[{ required: true, message: 'Audience is required!' }]}
        >
          <Radio.Group name='Audience' buttonStyle='solid' id='audience'>
            <Radio.Button value={'staff'}>Staff Only</Radio.Button>
            <Radio.Button value={'public'}>Public</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name='cost'
          label='Cost'
          htmlFor='cost'
          rules={[{ required: true, message: 'Cost is required!' }]}
        >
          <Radio.Group
            name='cost'
            buttonStyle='solid'
            onChange={onChangePrice}
            id='cost'
          >
            <Radio.Button value={'free'}>Free</Radio.Button>
            <Radio.Button value={'paid'}>Paid</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {showPrice && (
          <Form.Item
            label='Price'
            name='price'
            htmlFor='price'
            rules={[{ required: true, message: 'Price is required!' }]}
          >
            <TextArea rows={3} id='price' />
          </Form.Item>
        )}

        <Form.Item
          label={
            <span>
              <span className='requiredItem'>*</span>Venue
            </span>
          }
          htmlFor='venue'
        >
          <Form.Item
            name='venue'
            noStyle
            rules={[{ required: true, message: 'Venue is required!' }]}
          >
            <Select
              mode='multiple'
              placeholder='Please select venue(s)'
              style={{ width: 'calc(100% - 26px)' }}
              filterOption={filterOption}
              id='venue'
              allowClear
            >
              {venues.length > 0 &&
                venues.map((venue) => (
                  <Option key={venue.id} value={venue.id}>
                    {venue.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <PlusCircleTwoTone
            title='Add new Venue'
            style={{
              marginLeft: '0.5em',
              marginTop: '0.5em',
              fontSize: '16px',
            }}
            onClick={() => setVenueFormVisible(true)}
          />
        </Form.Item>
        <VenueCreateForm
          visible={venueFormVisible}
          onCreate={onCreateVenue}
          onCancel={() => setVenueFormVisible(false)}
        />

        <Form.Item
          label={
            <span>
              <span className='requiredItem'>*</span>Contact
            </span>
          }
          htmlFor='contact'
        >
          <Form.Item
            name='contact'
            noStyle
            rules={[{ required: true, message: 'Contact is required!' }]}
          >
            <Select
              showSearch
              placeholder='Please select event contact. This information will be displayed on your calendar entry'
              style={{ width: 'calc(100% - 26px)' }}
              filterOption={filterOption}
              id='contact'
              allowClear
            >
              {contacts.length > 0 &&
                contacts.map((contact) => (
                  <Option key={contact.id} value={contact.id}>
                    {[
                      `${contact.firstName} ${contact.lastName}`,
                      contact.email,
                      contact.telephone,
                    ].join(', ')}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <PlusCircleTwoTone
            title='Add new contact'
            style={{
              marginLeft: '0.5em',
              marginTop: '0.5em',
              fontSize: '16px',
            }}
            onClick={() => setContactFormVisible(true)}
          />
        </Form.Item>
        <ContactCreateForm
          visible={contactFormVisible}
          onCreate={onCreateContact}
          onCancel={() => setContactFormVisible(false)}
        />

        <Form.Item
          label={
            <span>
              <span className='requiredItem'>*</span>Event Type
            </span>
          }
          htmlFor='eventtype'
        >
          <Form.Item
            name='type'
            noStyle
            rules={[{ required: true, message: 'Event Type is required!' }]}
          >
            <Select
              placeholder='Please select event type'
              style={!isAdmin ? {} : { width: 'calc(100% - 26px)' }}
              showSearch={{ filter }}
              id='eventtype'
              allowClear
            >
              {eventTypes.length > 0 &&
                eventTypes.map((type) => (
                  <Option key={type.id} value={type.name}>
                    {type.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {isAdmin && (
            <PlusCircleTwoTone
              title='Add new Event Type'
              style={{
                marginLeft: '0.5em',
                marginTop: '0.5em',
                fontSize: '16px',
              }}
              onClick={() => setEventTypeFormVisible(true)}
            />
          )}
        </Form.Item>
        <BasicCreateForm
          visible={eventTypeFormVisible}
          onCreate={onCreateEventType}
          onCancel={() => setEventTypeFormVisible(false)}
          type='Event Type'
        />

        <Form.Item
          label={
            <span>
              <span className='requiredItem'>*</span>Produced By
            </span>
          }
          htmlFor='producer'
        >
          <Form.Item
            name='producer'
            noStyle
            id='producer'
            rules={[{ required: true, message: 'Produced by is required!' }]}
          >
            <Cascader
              options={options}
              changeOnSelect
              placeholder='Please select producer'
              style={!isAdmin ? {} : { width: 'calc(100% - 26px)' }}
              showSearch={{ filter }}
              allowClear
            />
          </Form.Item>
          {isAdmin && (
            <PlusCircleTwoTone
              title='Add new producer'
              style={{
                marginLeft: '0.5em',
                marginTop: '0.5em',
                fontSize: '16px',
              }}
              onClick={() => setProducerFormVisible(true)}
            />
          )}
        </Form.Item>
        <ProducerCreateForm
          visible={producerFormVisible}
          onCreate={onCreateProducer}
          onCancel={() => setProducerFormVisible(false)}
          type='Producer'
        />

        <Form.Item
          label={
            <span>
              <span className='requiredItem'>*</span>Area of Interest
            </span>
          }
          htmlFor='areaOfInterest'
        >
          <Form.Item
            name='areaOfInterest'
            noStyle
            rules={[
              {
                required: true,
                message: 'Area of interest is required!',
              },
            ]}
          >
            <Select
              placeholder='Please select area of interest(s)'
              mode='multiple'
              style={!isAdmin ? {} : { width: 'calc(100% - 26px)' }}
              filterOption={filterOption}
              id='areaOfInterest'
              allowClear
            >
              {areaOfInterests.length > 0 &&
                areaOfInterests.map((area) => (
                  <Option key={area.id} value={area.name}>
                    {area.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {isAdmin && (
            <PlusCircleTwoTone
              title='Add new Area of Interest'
              style={{
                marginLeft: '0.5em',
                marginTop: '0.5em',
                fontSize: '16px',
              }}
              onClick={() => setAreaOfInterestFormVisible(true)}
            />
          )}
        </Form.Item>
        <BasicCreateForm
          visible={areaOfInterestFormVisible}
          onCreate={onCreateAreaOfInterest}
          onCancel={() => setAreaOfInterestFormVisible(false)}
          type='Area of Interest'
        />

        <Form.Item
          label='Image'
          name='image'
          htmlFor='image'
          rules={[{ required: true, message: 'Image is required!' }]}
          validateStatus={image.validateStatus}
        >
          <>
            {mode === 'edit' && initialValues.image && !imagePreview && (
              <Col span={8}>
                <S3Image
                  imgKey={initialValues.image.key}
                  theme={{
                    photoImg: {
                      maxWidth: '100%',
                      maxHeight: '100%',
                      paddingBottom: '0.6em',
                    },
                  }}
                />
              </Col>
            )}
            {imagePreview && (
              <img
                className='image-preview'
                src={imagePreview}
                alt='Event thumbnail'
              />
            )}
            <PhotoPicker
              preview='hidden'
              title='Select an Image'
              id='image'
              onLoad={(url) => setImagePreview(url)}
              onPick={(file) => validateImage(file)}
              theme={{
                sectionBody: {
                  display: 'none',
                },
                sectionHeaderContent: {
                  display: 'none',
                },
              }}
            />
          </>
        </Form.Item>

        <Form.Item
          label={`More information /
                    Registration`}
          name='moreInfo'
          className='two-rows-label'
          htmlFor='moreInfo'
        >
          <TextArea
            rows={5}
            id='moreInfo'
            placeholder='Please use one single full url starting with http... Do not add more than one url or any additional wording.'
          />
        </Form.Item>

        <Form.Item label='Publish'>
          <Form.Item name='published' valuePropName='checked' noStyle>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              // defaultChecked={false}
              onChange={() => setPublishing(!publishing)}
            ></Switch>
          </Form.Item>
          <span
            style={{
              marginLeft: 8,
              fontStyle: 'italic',
              fontWeight: '0.8em',
            }}
          >
            {publishingText}
          </span>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Link to='/'>
              <Button>Cancel</Button>
            </Link>
            <Button type='primary' htmlType='submit'>
              {loading && <Spin indicator={antIcon} />}Save Event
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 5, span: 16 },
};

const rangeConfig = {
  rules: [
    { type: 'array', required: true, message: 'Please select start/end time!' },
  ],
};

export default withRouter(EventForm);
