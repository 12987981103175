import React from "react";
import { Modal, Form, Input } from "antd";

const ContactCreateForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title='Add new contact'
      okText='Create'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validation Failed:", info);
          });
      }}
    >
      <Form form={form} layout='vertical' name='contact_form'>
        <Form.Item name='title' label='Title'>
          <Input placeholder='Please enter title...' />
        </Form.Item>

        <Form.Item
          name='firstName'
          label='First Name'
          rules={[{ required: true, message: "Please enter first name" }]}
        >
          <Input placeholder='Please enter first name...' />
        </Form.Item>

        <Form.Item
          name='lastName'
          label='Last Name'
          rules={[{ required: true, message: "Please enter last name" }]}
        >
          <Input placeholder='Please enter last name...' />
        </Form.Item>

        <Form.Item name='address' label='Address'>
          <Input placeholder='Please enter address...' />
        </Form.Item>

        <Form.Item name='telephone' label='Telephone'>
          <Input placeholder='Please enter phone number...' />
        </Form.Item>

        <Form.Item
          name='email'
          label='Email'
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input placeholder='Please enter email' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContactCreateForm;
