import React, { useState } from 'react'
import { Popover, Button, Space } from 'antd';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

const DuplicatePopover = ({ record, duplicateHandler, children }) => {
  const [visible, setVisible] = useState(false)

  const handleDuplicate = () => {
    setVisible(false)
    duplicateHandler(record.id)
  }

  const handleVisibleChange = visible => setVisible(visible)

  return (
    <Popover
      placement="topRight"
      content={
        <div style={{textAlign: 'right'}}>
          <Space>
            <Button onClick={() => handleVisibleChange(false)}>Cancel</Button>
            <Button danger onClick={handleDuplicate}>YES</Button>
          </Space>
        </div>
      }
      title={
        <div style={{ padding: '1em'}}>
        <ExclamationCircleTwoTone twoToneColor='red' style={{marginRight: 8}}/>
        Are you sure you want to create a copy of this event?
        <p style={{fontSize: '0.85em', marginTop: "1em"}}>{record.name}</p>
        </div>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      { children }
    </Popover>
  );
}

export default DuplicatePopover;