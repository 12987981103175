import React from 'react';
import { Drawer, Button, Form, Input, Space } from 'antd';

const VenueDrawer = ({
  title,
  mode,
  isShowing,
  closeDrawer,
  handleAddVenue,
  handleEditVenue,
  onFinishFailed,
  currentEntry
}) => {

  return (
    <Drawer
      title={`${mode==='add' ? 'Add' : 'Edit'} ${title}`}
      width={640}
      onClose={closeDrawer}
      visible={isShowing}
      mode={mode}
    >
      <Form
        layout="vertical"
        initialValues={currentEntry}
        onFinish={mode==='add' ? handleAddVenue : handleEditVenue}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: `Please enter ${title} name` }]}
        >
          <Input placeholder={`Please enter ${title} name`} />
        </Form.Item>

        <Form.Item
          name="code"
          label="Venue Code"
        >
          <Input placeholder={`Please enter ${title} code`} />
        </Form.Item>

        <Form.Item
          name="location"
          label="Location"
        >
          <Input placeholder={`Please enter ${title} location`} />
        </Form.Item>

        <Form.Item style={{
          textAlign: 'right',
        }}>
          <Space >
            <Button onClick={closeDrawer}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default VenueDrawer