import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { TimePicker } from 'antd';

const { RangePicker } = TimePicker;

export const TimeRangePickerAutoaccept = (props) => {
  const rangePickerRef = useRef();

  const onBlur = () => {
    const rangePickerDomNode = ReactDOM.findDOMNode(rangePickerRef.current);
    const [startInput, endInput] = rangePickerDomNode.querySelectorAll(
      '.ant-picker-input input'
    );
    const startValue = moment(startInput.value, props.format);
    let endValue = moment(endInput.value, props.format);
    if (!endValue.isValid()) {
      endValue = null;
    }
    if (startValue && startValue.isValid() && props.onChange) {
      props.onChange([startValue, endValue]);
    }
  };

  return <RangePicker {...props} onBlur={onBlur} ref={rangePickerRef} />;
};
