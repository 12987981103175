import {
  SET_USER,
  SET_ADMIN,
  SET_LOADING,
  SET_CONSENT,
  ADD_AREA,
  SET_AREAS,
  UPDATE_AREA,
  DELETE_AREA,
  ADD_CONTACT,
  SET_CONTACTS,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  ADD_EVENT,
  SET_EVENTS,
  UPDATE_EVENT,
  DELETE_EVENT,
  ADD_EVENT_TYPE,
  SET_EVENT_TYPES,
  UPDATE_EVENT_TYPE,
  DELETE_EVENT_TYPE,
  ADD_PRODUCER,
  SET_PRODUCERS,
  UPDATE_PRODUCER,
  DELETE_PRODUCER,
  ADD_VENUE,
  SET_VENUES,
  UPDATE_VENUE,
  DELETE_VENUE,
} from "../type";

const eventReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_CONSENT:
      return {
        ...state,
        consent: action.payload,
      };
    case ADD_AREA:
      return {
        ...state,
        areaOfInterests: [action.payload, ...state.areaOfInterests],
      };
    case SET_AREAS:
      return {
        ...state,
        areaOfInterests: action.payload,
      };
    case UPDATE_AREA:
      return {
        ...state,
        areaOfInterests: state.areaOfInterests.map((area) =>
          area.id === action.payload.id ? action.payload : area
        ),
      };
    case DELETE_AREA:
      return {
        ...state,
        areaOfInterests: state.areaOfInterests.filter(
          (area) => area.id !== action.payload.id
        ),
      };
    case ADD_CONTACT:
      return {
        ...state,
        contacts: [action.payload, ...state.contacts],
      };
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.map((contact) =>
          contact.id === action.payload.id ? action.payload : contact
        ),
      };
    case DELETE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.filter(
          (contact) => contact.id !== action.payload.id
        ),
      };
    case ADD_EVENT:
      return {
        ...state,
        events: [action.payload, ...state.events],
      };
    case SET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case UPDATE_EVENT:
      return {
        ...state,
        events: state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        ),
      };
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((event) => event.id !== action.payload.id),
      };
    case ADD_EVENT_TYPE:
      return {
        ...state,
        eventTypes: [action.payload, ...state.eventTypes],
      };
    case SET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: action.payload,
      };
    case UPDATE_EVENT_TYPE:
      return {
        ...state,
        eventTypes: state.eventTypes.map((type) =>
          type.id === action.payload.id ? action.payload : type
        ),
      };
    case DELETE_EVENT_TYPE:
      return {
        ...state,
        eventTypes: state.eventTypes.filter(
          (type) => type.id !== action.payload.id
        ),
      };
    case ADD_PRODUCER:
      return {
        ...state,
        producers: [action.payload, ...state.producers],
      };
    case SET_PRODUCERS:
      return {
        ...state,
        producers: action.payload,
      };
    case UPDATE_PRODUCER:
      return {
        ...state,
        producers: state.producers.map((producer) =>
          producer.id === action.payload.id ? action.payload : producer
        ),
      };
    case DELETE_PRODUCER:
      return {
        ...state,
        producers: state.producers.filter(
          (producer) => producer.id !== action.payload.id
        ),
      };
    case ADD_VENUE:
      return {
        ...state,
        venues: [action.payload, ...state.venues],
      };
    case SET_VENUES:
      return {
        ...state,
        venues: action.payload,
      };
    case UPDATE_VENUE:
      return {
        ...state,
        venues: state.venues.map((venue) =>
          venue.id === action.payload.id ? action.payload : venue
        ),
      };
    case DELETE_VENUE:
      return {
        ...state,
        venues: state.venues.filter((venue) => venue.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default eventReducer;
