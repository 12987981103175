import React, { useContext } from 'react';
import { Modal, Button } from 'antd';
import EventContext from '../../context/event/eventContext';

const ConsentModal = () => {
  const eventContext = useContext(EventContext);
  const { consent, setConsent } = eventContext;

  const handleOk = () => {
    setConsent(true);
  };

  return (
    <Modal
      title='ADDING EVENTS TO THE ONLINE EVENT CALENDAR'
      width={800}
      visible={!consent}
      closable={false}
      footer={[
        <Button key='submit' type='primary' onClick={handleOk}>
          CONTINUE
        </Button>,
      ]}
    >
      <p>
        The aim of the University's central online event calendar is to inform
        our audiences, drive participation, and showcase the depth, breadth and
        quality of events run by the University. The calendar is to be used for
        University events only, which may or may not be convened jointly with an
        external party, which may be an associated or affiliated entity. The
        calendar is not to be used for events held entirely by external parties.
      </p>

      <p>
        Entries on this calendar are visible to everyone worldwide, and are a
        showcase for the university. The quality of your content should reflect
        this, and guidance on writing style can be found{' '}
        <a
          href='https://intranet.sydney.edu.au/services/marketing-communications/writing-editing.html'
          rel='noreferrer'
          target='_blank'
        >
          here
        </a>
        .
      </p>

      <p>
        Only use this if your event is open to everyone (or all staff) - do not
        use the calendar for invitation only events.
      </p>

      <p>
        Please note that the calendar is an information and marketing tool and
        not a registration system - although you can link to your event
        registration page.
      </p>

      <p>
        If you are unsure about including your event or have any further
        questions, contact{' '}
        <a href='mailto:events.calendaradmin@sydney.edu.au'>
          events.calendaradmin@sydney.edu.au
        </a>{' '}
        for further advice.
      </p>

      <p>By clicking continue, you are confirming that :</p>

      <ul>
        <li>You are a staff member at the University of Sydney</li>
        <li>
          Your event is appropriate to be included on an externally facing
          calendar OR your event is appropriate for all staff and will be tagged
          accordingly
        </li>
        <li>
          Your event copy has been edited and proofread to a standard consistent
          with the University's writing and editing guidelines
        </li>
        <li>
          You will check all details are correct before clicking 'publish' on
          your event entry
        </li>
        <li>
          You are responsible for, and will make any changes/edits to your event
          copy should they be required (eg. change of date/speaker, cancellation
          etc)
        </li>
      </ul>
    </Modal>
  );
};

export default ConsentModal;
