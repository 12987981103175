import React from "react";
import { Modal, Form, Input } from "antd";

const VenueCreateForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title='Add new venue'
      okText='Create'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validation Failed:", info);
          });
      }}
    >
      <Form form={form} layout='vertical' name='venue_form'>
        <Form.Item
          name='name'
          label='Name'
          rules={[{ required: true, message: "Please enter venue name" }]}
        >
          <Input placeholder='Please enter venue name...' />
        </Form.Item>

        <Form.Item name='code' label='Venue Code'>
          <Input placeholder='Please enter venue code...' />
        </Form.Item>

        <Form.Item name='location' label='Location'>
          <Input placeholder='Please enter venue location...' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default VenueCreateForm;
