import React, { useState, useContext } from 'react';
import { Table, Space, Button, notification } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import {
  createAreaOfInterest,
  updateAreaOfInterest,
  deleteAreaOfInterest,
} from '../../graphql/mutations';
import DeletePopover from '../DeletePopover';
import BasicDrawer from '../BasicDrawer';
import {
  EditOutlined,
  DeleteFilled,
  PlusCircleFilled,
} from '@ant-design/icons';
import EventContext from '../../context/event/eventContext';

const AreaList = () => {
  const eventContext = useContext(EventContext);
  const { isAdmin, areaOfInterests } = eventContext;

  const [isShowing, setIsShowing] = useState(false);
  const [mode, setMode] = useState('add');
  const [currentArea, setCurrentArea] = useState({ id: null, name: '' });

  const areaColumns = [
    {
      title: 'AREA OF INTEREST',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'ACTION',
      key: 'action',
      width: '200px',
      render: (text, record) =>
        record.showButtons ? (
          <Space size='middle'>
            <Button
              shape='round'
              size='small'
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            >
              Edit
            </Button>
            <DeletePopover deleteHandler={handleDeleteArea} record={record}>
              <Button shape='round' size='small' danger icon={<DeleteFilled />}>
                Delete
              </Button>
            </DeletePopover>
          </Space>
        ) : (
          '-'
        ),
    },
  ];

  const handleEdit = (area) => {
    setMode('edit');
    setCurrentArea({
      id: area.id,
      name: area.name,
    });
    setIsShowing(true);
  };

  const closeDrawer = () => {
    setMode('add');
    setCurrentArea({ id: null, name: '' });
    setIsShowing(false);
  };

  const handleAddArea = async (values) => {
    // console.log('Add Area:', values);
    try {
      const input = { name: values.name };
      await API.graphql(graphqlOperation(createAreaOfInterest, { input }));
      closeDrawer();
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Area of interest has been added successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditArea = async (values) => {
    // console.log("Edit Area", values)
    try {
      const input = {
        id: currentArea.id,
        name: values.name,
      };
      await API.graphql(graphqlOperation(updateAreaOfInterest, { input }));
      closeDrawer();
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Area of interest has been updated successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteArea = async (id) => {
    try {
      const input = { id };
      await API.graphql(graphqlOperation(deleteAreaOfInterest, { input }));
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Area of interest has been deleted successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onFinishFailed = (error) => {
    console.error('Submission Failed:', error);
  };

  const dataSource = areaOfInterests.map((item) => {
    return {
      ...item,
      key: item.id,
      showButtons: isAdmin,
    };
  });

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '8px',
        }}
      >
        <h2>
          Total number of Area of Interests
          <span className='badge'>{dataSource.length}</span>
        </h2>
        {isAdmin && (
          <Button
            shape='round'
            size='small'
            type='primary'
            onClick={() => setIsShowing(true)}
          >
            <PlusCircleFilled /> Add Area of Interest
          </Button>
        )}
      </div>

      {isShowing && (
        <BasicDrawer
          title='area of interest'
          mode={mode}
          isShowing={isShowing}
          closeDrawer={closeDrawer}
          handleAddName={handleAddArea}
          handleEditName={handleEditArea}
          onFinishFailed={onFinishFailed}
          currentEntry={currentArea}
        />
      )}

      <Table
        dataSource={dataSource}
        columns={areaColumns}
        pagination={{ pageSize: 50 }}
        size='small'
      />
    </>
  );
};

export default AreaList;
